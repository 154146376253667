import { Country } from './country';

export type ElementType<T extends ReadonlyArray<unknown>> =
    // eslint-disable-next-line @typescript-eslint/no-shadow
    T extends ReadonlyArray<infer ElementType> ? ElementType : never;

export enum Currency {
    EUR = 'EUR',
    USD = 'USD',
    GBP = 'GBP',
}

export enum PRODUCT_TYPE {
    photobook = 'photobook',
}

export enum SKU {
    PHOTOBOOK_HARDCOVER_A4_HOR = 'PHOTOBOOK_HARDCOVER_A4_HOR',
}

export type PhotobookInfo = {
    pages: number;
    jobName: string;
};

export type ProductInfo = {
    photobook: PhotobookInfo;
};

export type TCartItem = {
    id: string;
    productType: PRODUCT_TYPE;
    productInfo: ProductInfo;
    name: string;
    quantity: number;
    jobName: string;
};

export type Address = {
    VAT?: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    company?: string;
    country: Country;
    firstName: string;
    lastName: string;
    phone?: string;
    postalCode: string;
    state?: string;
};

export type CartAddress = {
    billing: Address;
    billingSameAsShipping: boolean;
    shipping: Address;
};

export type TCart = {
    email: string;
    cartItems: TCartItem[];
    address: CartAddress;
    currency: Currency;
    locale: string;
    coupons?: OrderCouponInfo[];
};

export type OrderLine = {
    productType: PRODUCT_TYPE;
    productInfo: ProductInfo;
    quantity: number;
    price: number;
};

type Amount = {
    currency: Currency;
    amount: number;
};

export enum CouponType {
    PERCENT = 'PERCENT',
    VALUE = 'VALUE',
}

export enum CouponCategory {
    GIFT_CARD = 'GIFT_CARD',
    COUPON = 'COUPON',
}

export type OrderCouponInfo = {
    coupon: string;
    type: CouponType;
    category: CouponCategory;
    percent?: number;
    value?: Amount[];
    freeShipping: boolean;
};

export type Coupon = {
    coupon: string;
    category: CouponCategory;
    constant: string;
    createTime: number;
    type: CouponType;
    percent?: number;
    value?: Amount[];
    freeShipping: boolean;

    validFrom?: number;
    validTo?: number;
    timesValid?: number;
    timesValidEnabled: boolean;
    timesUsed: number;

    validProductTypes: PRODUCT_TYPE[];
};

export enum OrderStatus {
    PENDING = 'PENDING',
    ON_HOLD = 'ON_HOLD',
    PAYMENT_FAILED = 'PAYMENT_FAILED',
    PAID = 'PAID',
    PDF_GENERATE_QUEUED = 'PDF_GENERATE_QUEUED',
    PDF_GENERATED = 'PDF_GENERATED',
    SENT_TO_PRINTER = 'SENT_TO_PRINTER',
    SHIPPED = 'SHIPPED',
    CANCELLED = 'CANCELLED',
    GELATO_PASSED = 'GELATO_PASSED',
    GELATO_PRINTED = 'GELATO_PRINTED',
}

export type StatusHistoryItem = {
    createdAt: number;
    status: OrderStatus;
    note?: string;
};

export type OrderTracking = {
    trackingIpAddress: string;
    trackingDevice: string;
    trackingOs: string;
    utmSource: string;
    utmMedium: string;
    utmCampaign: string;
    utmTerm: string;
    utmContent: string;
    referrer: string;
    abTestData: string;

    lastTouchUtmSource: string;
    lastTouchUtmMedium: string;
    lastTouchUtmCampaign: string;
    lastTouchUtmTerm: string;
    lastTouchUtmContent: string;
    lastTouchReferrer: string;

    trackingUserId: string;
};

export type Order = {
    orderId: string;
    createTime: number;
    constant: string; // For dynamodb sorting
    confirmationKey: string;
    mollieId: string;
    gelatoId?: string;
    email: string;
    billing: Address;
    billingSameAsShipping: boolean;
    shipping: Address;

    orderLines: OrderLine[];
    status: OrderStatus;
    statusHistory: StatusHistoryItem[];

    currency: Currency;
    locale: string;

    shippingPrice: number;
    taxPrice: number;
    taxPercentage: number;
    totalPrice: number;
    totalPriceExclCoupons: number;

    shippingPriceEUR: number;
    taxPriceEUR: number;
    totalPriceEUR: number;
    totalPriceExclCouponsEUR: number;

    coupons: OrderCouponInfo[];

    trackingUrl?: string;
} & OrderTracking;

export enum MolliePaymentStatus {
    OPEN = 'OPEN',
    PAID = 'PAID',
    CANCELLED = 'CANCELLED',
    FAILED = 'FAILED',
    EXPIRED = 'EXPIRED',
}
