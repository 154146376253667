import { Country } from './types/country';
import {
    CouponCategory,
    CouponType,
    Currency,
    PRODUCT_TYPE,
    TCart,
    TCartItem,
} from './types/model';
import tax from '../tax';
import { required } from '../src/helpers/util';
import { getShippingPrice } from './shipping';
// Pricing is in cents!

export const pricing = {
    STANDARD_PERCENT_DISCOUNT: 30,
    PHOTO_BOOK: {
        BASE_PRICE: {
            // excludes any page
            [Currency.EUR]: 4099,
            [Currency.GBP]: 4099,
            [Currency.USD]: 4099,
        },
        PAGE_PRICE: {
            [Currency.EUR]: 130,
            [Currency.GBP]: 130,
            [Currency.USD]: 130,
        },
    },
};

const getTaxPrice = (
    country: Country | undefined,
    totalPaid: number,
    boughtGiftCardAmount: number,
    usedGiftcardAmount: number
) => {
    if (!country) {
        return {
            taxPercentage: 0,
            taxPrice: 0,
        };
    }

    const taxPercentage = tax[country as keyof typeof tax] || tax.default;
    const priceToTax = totalPaid - boughtGiftCardAmount + usedGiftcardAmount;
    return {
        taxPrice: Math.floor(
            (priceToTax / (1 + taxPercentage / 100)) * (taxPercentage / 100)
        ),
        taxPercentage,
    };
};

export const calculateCartItemPrice = (
    cartItem: Pick<TCartItem, 'productType' | 'productInfo' | 'quantity'>,
    currency: Currency
) => {
    if (cartItem.productType === PRODUCT_TYPE.photobook) {
        const pages = cartItem.productInfo.photobook.pages;
        const spreads = Math.ceil(pages / 2);
        const pagesToPrint = spreads * 2;

        const price = pricing.PHOTO_BOOK.BASE_PRICE[currency] +
            pricing.PHOTO_BOOK.PAGE_PRICE[currency] * pagesToPrint;

        const roundedPrice = Math.ceil(price / 100) * 100 - 1;
        return roundedPrice * cartItem.quantity;
    }

    throw Error('Invalid product type');
};

export const calculateCartPrice = (
    cart: Partial<TCart> & { cartItems: TCart['cartItems']; currency: Currency }
) => {
    let totalPrice = 0;
    let totalPriceExclCoupons = 0;
    cart.cartItems.forEach((cartItem) => {
        const cartItemPrice = calculateCartItemPrice(cartItem, cart.currency);

        totalPrice += cartItemPrice;
        totalPriceExclCoupons += cartItemPrice;
    });

    // Get all non-gift card coupons and subtract their discount(s)
    // Apply this only on things that are not gift cards
    (cart.coupons || [])
        .filter((coupon) => coupon.category !== CouponCategory.GIFT_CARD)
        .forEach((coupon) => {
            if (coupon.type === CouponType.PERCENT) {
                totalPrice = Math.max(
                    0,
                    totalPrice - (totalPrice * required(coupon.percent)) / 100
                );
            } else if (coupon.type === CouponType.VALUE) {
                totalPrice = Math.max(
                    0,
                    totalPrice -
                    100 *
                    (coupon.value?.find(
                        (amount) => amount?.currency === cart.currency
                    )?.amount || 0)
                );
            }
        });

    // If you don't have a percent coupon in your cart, apply the default discount
    if (!cart.coupons?.find((coupon) => coupon.type === CouponType.PERCENT)) {
        totalPrice = Math.max(
            0,
            totalPrice - (totalPrice * pricing.STANDARD_PERCENT_DISCOUNT) / 100
        );
    }

    const shippingPrice = getShippingPrice(cart.address?.billing.country || 'default', cart.currency);

    totalPrice += shippingPrice;
    totalPriceExclCoupons += shippingPrice;

    // Apply gift cards
    (cart.coupons || [])
        .filter((coupon) => coupon.category === CouponCategory.GIFT_CARD)
        .forEach((coupon) => {
            if (coupon.type === CouponType.PERCENT) {
                totalPrice = Math.max(
                    0,
                    totalPrice - (totalPrice * required(coupon.percent)) / 100
                );
            } else if (coupon.type === CouponType.VALUE) {
                totalPrice = Math.max(
                    0,
                    totalPrice -
                    100 *
                    (coupon.value?.find(
                        (amount) => amount?.currency === cart.currency
                    )?.amount || 0)
                );
            }
        });

    const { taxPrice, taxPercentage } = getTaxPrice(
        cart.address?.billing.country,
        totalPrice,
        0,
        0
    );

    return {
        totalPrice: Math.floor(totalPrice),
        totalPriceExclCoupons: Math.floor(totalPriceExclCoupons),
        shippingPrice: Math.floor(shippingPrice),
        taxPrice: Math.floor(taxPrice),
        taxPercentage,
    };
};
