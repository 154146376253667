import { getCart, removeCoupon } from './cart';

export const getCoupon = async (id: string) => {
    const cart = getCart();

    const response = await fetch(
        `${process.env.GATSBY_PAYMENT_API_ENDPOINT}/validateCoupon/`,
        {
            method: 'post',
            body: JSON.stringify({
                couponId: id,
                cart,
            }),
        }
    );
    const result = await response.json();
    return result.result.coupon;
};

export const validateCoupons = async () => {
    const cart = getCart();

    await Promise.all(
        (cart?.coupons ?? []).map(async (coupon) => {
            if (!coupon.coupon) {
                return;
            }
            const result = await getCoupon(coupon.coupon.toUpperCase());
            if (!result?.coupon) {
                removeCoupon(coupon.coupon.toUpperCase());
            }
        })
    );
};
