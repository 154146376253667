import React from 'react';
import { PageProvider } from './PageContext';
import SimpleLocalize from './Localize';

export const RootComponent = (props: any) => {
    if (props.backoffice) {
        return (
            <PageProvider context={props.pageContext}>
                {props.children}
            </PageProvider>
        );
    }

    return (
        <PageProvider context={props.pageContext}>
            <SimpleLocalize>{props.children}</SimpleLocalize>
        </PageProvider>
    );
};
