exports.components = {
  "component---src-backoffice-coupons-create-tsx": () => import("./../../../src/backoffice/coupons/create.tsx" /* webpackChunkName: "component---src-backoffice-coupons-create-tsx" */),
  "component---src-backoffice-coupons-edit-dynamic-tsx": () => import("./../../../src/backoffice/coupons/edit.dynamic.tsx" /* webpackChunkName: "component---src-backoffice-coupons-edit-dynamic-tsx" */),
  "component---src-backoffice-coupons-tsx": () => import("./../../../src/backoffice/coupons.tsx" /* webpackChunkName: "component---src-backoffice-coupons-tsx" */),
  "component---src-backoffice-index-tsx": () => import("./../../../src/backoffice/index.tsx" /* webpackChunkName: "component---src-backoffice-index-tsx" */),
  "component---src-backoffice-login-tsx": () => import("./../../../src/backoffice/login.tsx" /* webpackChunkName: "component---src-backoffice-login-tsx" */),
  "component---src-backoffice-orders-tsx": () => import("./../../../src/backoffice/orders.tsx" /* webpackChunkName: "component---src-backoffice-orders-tsx" */),
  "component---src-backoffice-orders-view-dynamic-tsx": () => import("./../../../src/backoffice/orders/view.dynamic.tsx" /* webpackChunkName: "component---src-backoffice-orders-view-dynamic-tsx" */),
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-cart-tsx": () => import("./../../../src/templates/cart.tsx" /* webpackChunkName: "component---src-templates-cart-tsx" */),
  "component---src-templates-config-tsx": () => import("./../../../src/templates/config.tsx" /* webpackChunkName: "component---src-templates-config-tsx" */),
  "component---src-templates-create-facebook-tsx": () => import("./../../../src/templates/createFacebook.tsx" /* webpackChunkName: "component---src-templates-create-facebook-tsx" */),
  "component---src-templates-faq-tsx": () => import("./../../../src/templates/faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-no-facebook-photo-permission-tsx": () => import("./../../../src/templates/noFacebookPhotoPermission.tsx" /* webpackChunkName: "component---src-templates-no-facebook-photo-permission-tsx" */),
  "component---src-templates-not-enough-photos-tsx": () => import("./../../../src/templates/notEnoughPhotos.tsx" /* webpackChunkName: "component---src-templates-not-enough-photos-tsx" */),
  "component---src-templates-payment-return-tsx": () => import("./../../../src/templates/paymentReturn.tsx" /* webpackChunkName: "component---src-templates-payment-return-tsx" */),
  "component---src-templates-payment-tsx": () => import("./../../../src/templates/payment.tsx" /* webpackChunkName: "component---src-templates-payment-tsx" */),
  "component---src-templates-photobook-edit-dynamic-tsx": () => import("./../../../src/templates/photobook/edit.dynamic.tsx" /* webpackChunkName: "component---src-templates-photobook-edit-dynamic-tsx" */),
  "component---src-templates-photobook-tsx": () => import("./../../../src/templates/photobook.tsx" /* webpackChunkName: "component---src-templates-photobook-tsx" */),
  "component---src-templates-photobooks-tsx": () => import("./../../../src/templates/photobooks.tsx" /* webpackChunkName: "component---src-templates-photobooks-tsx" */),
  "component---src-templates-privacy-tsx": () => import("./../../../src/templates/privacy.tsx" /* webpackChunkName: "component---src-templates-privacy-tsx" */),
  "component---src-templates-quality-tsx": () => import("./../../../src/templates/quality.tsx" /* webpackChunkName: "component---src-templates-quality-tsx" */),
  "component---src-templates-refund-tsx": () => import("./../../../src/templates/refund.tsx" /* webpackChunkName: "component---src-templates-refund-tsx" */),
  "component---src-templates-shipping-tsx": () => import("./../../../src/templates/shipping.tsx" /* webpackChunkName: "component---src-templates-shipping-tsx" */),
  "component---src-templates-terms-tsx": () => import("./../../../src/templates/terms.tsx" /* webpackChunkName: "component---src-templates-terms-tsx" */)
}

