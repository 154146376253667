import { DependencyList, useEffect } from 'react';

export const useAsyncEffect = (
    callback: () => Promise<void> | void | (() => unknown),
    deps?: DependencyList
) => {
    useEffect(() => {
        callback();
    }, deps);
};
