export default {
    AT: 20,
    BE: 21,
    BG: 20,
    CY: 19,
    CZ: 21,
    DE: 19,
    DK: 25,
    EE: 20,
    ES: 21,
    FI: 24,
    FR: 20,
    GB: 20,
    GR: 24,
    HR: 25,
    HU: 27,
    IE: 23,
    IT: 22,
    LT: 21,
    LV: 21,
    MT: 18,
    NL: 21,
    PL: 23,
    PT: 23,
    RO: 19,
    SE: 25,
    SI: 22,
    SK: 20,
    default: 0,
    LU: 17,
};
