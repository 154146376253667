import React, { useContext } from 'react';
import { IntlProvider } from 'react-intl';
import { PageContext } from './PageContext';
import messages from '../../i18n-translations.json';

function SimpleLocalize(props: { children: any }) {
    const context = useContext(PageContext);
    return (
        <IntlProvider
            locale={context.locale.split('-')[0]}
            messages={
                messages[context.locale.split('-')[0] as keyof typeof messages]
            }
        >
            {props.children}
        </IntlProvider>
    );
}

export default SimpleLocalize;
