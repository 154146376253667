import merge from 'deepmerge';
// Sync cart with localStorage

import {
    CartAddress,
    Currency,
    OrderCouponInfo,
    TCart,
    TCartItem,
} from '../../shared/types/model';
import { createTrackingProductFrontend, track } from './track';
import { Events } from '../../shared/types/tracking';

export const getCart = () => {
    const cart = localStorage.getItem('cart');
    if (cart) {
        return JSON.parse(cart) as Partial<TCart>;
    }

    return undefined;
};

const saveCart = (cart: Partial<TCart>) => {
    localStorage.setItem('cart', JSON.stringify(cart));
};

export const setCartAddress = (address: CartAddress) => {
    let cart = getCart();
    if (!cart) {
        cart = {};
    }

    cart.address = address;
    saveCart(cart);
};

export const addCoupon = (coupon: OrderCouponInfo) => {
    let cart = getCart();
    if (!cart) {
        cart = {};
    }

    if (!cart.coupons) {
        cart.coupons = [];
    }

    cart.coupons.push(coupon);
    saveCart(cart);
};

export const removeCoupon = (couponId: string) => {
    let cart = getCart();
    if (!cart) {
        cart = {};
    }

    if (!cart.coupons) {
        cart.coupons = [];
    }

    cart.coupons = cart.coupons.filter((coupon) => coupon.coupon !== couponId);
    saveCart(cart);
};

export const setCartEmail = (email: string) => {
    let cart = getCart();
    if (!cart) {
        cart = {};
    }

    cart.email = email;
    saveCart(cart);
};

export const addCartItem = (cartItem: TCartItem) => {
    let cart = getCart();
    if (!cart) {
        cart = {};
    }

    // Below is the code for multi-item support in the cart, but
    // for now we only support 1 item in the cart, so we always
    // set the cart to this.
    cart.cartItems = [cartItem];

    // if (!cart.cartItems) {
    //     cart.cartItems = [];
    // }

    // // Replace cart item if it is already there
    // if (
    //     cart.cartItems.find(
    //         (_cartItem) => _cartItem.jobName === cartItem.jobName
    //     )
    // ) {
    //     const index = cart.cartItems.findIndex(
    //         (_cartItem) => _cartItem.jobName === cartItem.jobName
    //     );
    //     cart.cartItems.splice(index, 1);
    // }

    // cart.cartItems.push(cartItem);

    saveCart(cart);
};

export const decrementCartItem = (
    cartItemId: number,
    currency: Currency,
    locale: string
) => {
    const cart = getCart();
    if (!cart?.cartItems?.[cartItemId]) {
        return;
    }

    cart.cartItems[cartItemId].quantity -= 1;
    if (cart.cartItems[cartItemId].quantity <= 0) {
        track(
            Events.ProductRemoved,
            createTrackingProductFrontend({
                jobIdOrName: cart.cartItems[cartItemId].jobName,
                productType: cart.cartItems[cartItemId].productType,
                currency,
                locale,
            })
        );

        cart.cartItems.splice(cartItemId, 1);
    }

    saveCart(cart);
};

export const incrementCartItem = (cartItemId: number) => {
    const cart = getCart();
    if (!cart?.cartItems?.[cartItemId]) {
        return;
    }

    cart.cartItems[cartItemId].quantity += 1;

    saveCart(cart);
};

export const setCartInfo = (info: Partial<TCart>) => {
    const cart = getCart();

    saveCart(merge(cart || {}, info));
};
