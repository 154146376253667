import { Currency, PRODUCT_TYPE, SKU } from './model';

export enum Events {
    StartVisit = 'Start Visit',
    Pageview = 'Pageview',

    StartAutoflow = 'Start Autoflow',
    AutoflowStatusUpdated = 'Autoflow Status Updated',

    CouponEntered = 'Coupon Entered',
    CouponApplied = 'Coupon Applied',
    CouponRemoved = 'Coupon Removed',
    CouponDenied = 'Coupon Denied',

    Qvisit05 = 'Qvisit-05sec',
    Qvisit10 = 'Qvisit-10sec',
    Qvisit15 = 'Qvisit-15sec',
    Qvisit30 = 'Qvisit-30sec',
    Qvisit60 = 'Qvisit-60sec',

    ProductCreated = 'Product Created',
    ProductViewed = 'Product Viewed',
    ProductAdded = 'Product Added',
    ProductRemoved = 'Product Removed',
    CartViewed = 'Cart Viewed',
    CheckoutStarted = 'Checkout Started',
    PaymentMethodStarted = 'Payment Method Started',

    PaymentInfoEntered = 'Payment Info Entered',
    PaymentStarted = 'Payment Started',
    OrderCompleted = 'Order Completed',
    OrderShipped = 'Order Shipped',
    ProductOrdered = 'Product Ordered',

    EditDialogOpened = 'Edit Dialog Opened',
    EditPageRemoved = 'Edit Page Removed',
    EditSpreadAdded = 'Edit Spread Added',
    EditPhotoRemoved = 'Edit Photo Removed',
    EditPhotoAdded = 'Edit Photo Added',
    EditPhotoSaved = 'Edit Photo Saved',
    EditPhotoUploaded = 'Edit Photo Uploaded',
    EditPhotoReplaced = 'Edit Photo Replaced',
    EditPhotoMadeCover = 'Edit Photo Made Cover',
}

export interface EventProps {
    [Events.StartVisit]: Record<string, never>;

    [Events.Pageview]: Record<string, never>;
    [Events.StartAutoflow]: StartAutoflowProps;
    [Events.AutoflowStatusUpdated]: AutoflowStatusUpdatedProps;

    [Events.CouponEntered]: CouponProps;
    [Events.CouponApplied]: CouponProps;
    [Events.CouponRemoved]: CouponProps;
    [Events.CouponDenied]: CouponProps;

    [Events.Qvisit05]: Record<string, never>;
    [Events.Qvisit10]: Record<string, never>;
    [Events.Qvisit15]: Record<string, never>;
    [Events.Qvisit30]: Record<string, never>;
    [Events.Qvisit60]: Record<string, never>;

    [Events.ProductCreated]: ProductCreatedProps;
    [Events.ProductViewed]: ProductViewedProps;
    [Events.ProductAdded]: ProductAddedProps;
    [Events.ProductRemoved]: ProductRemovedProps;
    [Events.CartViewed]: CartViewedProps;

    [Events.PaymentInfoEntered]: PaymentInfoEnteredProps;
    [Events.PaymentStarted]: PaymentStartedProps;
    [Events.CheckoutStarted]: CheckoutStartedProps;
    [Events.PaymentMethodStarted]: PaymentMethodStartedProps;

    [Events.OrderCompleted]: OrderCompletedProps;
    [Events.OrderShipped]: OrderShippedProps;
    [Events.ProductOrdered]: ProductOrderedProps;

    [Events.EditDialogOpened]: EditDialogOpenedProps;
    [Events.EditPhotoRemoved]: EditPhotoRemovedProps;
    [Events.EditPageRemoved]: EditPageRemovedProps;
    [Events.EditSpreadAdded]: EditSpreadAddedProps;
    [Events.EditPhotoAdded]: EditPhotoAddedProps;
    [Events.EditPhotoSaved]: EditPhotoSavedProps;
    [Events.EditPhotoUploaded]: EditPhotoUploadedProps;
    [Events.EditPhotoReplaced]: EditPhotoReplacedProps;
    [Events.EditPhotoMadeCover]: EditPhotoMadeCoverProps;
}

type CouponProps = {
    coupon_id: string;
};

export type TrackingProduct = {
    cart_id: string;
    product_id: string;
    sku: SKU;
    category: PRODUCT_TYPE;
    name: PRODUCT_TYPE;
    translatedName: string;
    brand: string;
    variant: SKU;
    currency: Currency;
    quantity: number;
    price: number;
    priceEUR?: number;
    url: string;
    image_url: string;

    amount_photos: number;
    amount_pages: number;
    productGenerationError: number;
};

type ProductViewedProps = TrackingProduct;

type ProductCreatedProps = {
    type: PRODUCT_TYPE;
    error: number;
    amount_photos: number;
    amount_pages: number;
};

type ProductAddedProps = TrackingProduct;
type ProductRemovedProps = TrackingProduct;
type CartViewedProps = {
    cart_id: string;
    products: TrackingProduct[];
};
type PaymentInfoEnteredProps = {
    checkout_id: string;
    cart_id: string;
    products: TrackingProduct[];
};
type PaymentStartedProps = {
    checkout_id: string;
    cart_id: string;
    products: TrackingProduct[];
};

type CheckoutStartedProps = {
    order_id: string;
    affiliation: string;
    value: number;
    revenue: number;
    shipping: number;
    tax: number;
    taxPercentage: number;
    discount: number;
    subtotal: number;
    total: number;
    coupon?: string;
    coupons: string[];
    currency: Currency;
    products: TrackingProduct[];
};

type PaymentMethodStartedProps = {
    order_id: string;
    affiliation: string;
    value: number;
    revenue: number;
    shipping: number;
    tax: number;
    taxPercentage: number;
    discount: number;
    subtotal: number;
    total: number;
    coupon?: string;
    coupons: string[];
    currency: Currency;
    products: TrackingProduct[];
}

type OrderCompletedProps = {
    order_id: string;
    locale: string;
    email: string;
    affiliation: string;
    value: number;
    revenue: number;
    shipping: number;
    tax: number;
    taxPercentage: number;
    discount: number;
    subtotal: number;
    total: number;

    valueEUR: number;
    revenueEUR: number;
    shippingEUR: number;
    subtotalEUR: number;
    totalEUR: number;
    taxEUR: number;
    discountEUR: number;

    coupon?: string;
    coupons: string[];
    currency: Currency;
    products: TrackingProduct[];

    utm_source?: string;
    utm_term?: string;
    utm_content?: string;
    utm_medium?: string;
    utm_campaign?: string;
    referrer?: string;
    'utm_source [last touch]'?: string;
    'utm_term [last touch]'?: string;
    'utm_content [last touch]'?: string;
    'utm_medium [last touch]'?: string;
    'utm_campaign [last touch]'?: string;
    'referrer [last touch]'?: string;

    billingCountry: string;
    shippingCountry: string;
};

type OrderShippedProps = {
    order_id: string;
    email: string;
    locale: string;

    products: TrackingProduct[];
    trackingUrl: string;
};

type ProductOrderedProps = {
    email: string;
    locale: string;

    utm_source?: string;
    utm_term?: string;
    utm_content?: string;
    utm_medium?: string;
    utm_campaign?: string;
    referrer?: string;
    'utm_source [last touch]'?: string;
    'utm_term [last touch]'?: string;
    'utm_content [last touch]'?: string;
    'utm_medium [last touch]'?: string;
    'utm_campaign [last touch]'?: string;
    'referrer [last touch]'?: string;

    billingCountry: string;
    shippingCountry: string;
} & TrackingProduct;

type StartAutoflowProps = {
    datasource: string;
    fromYear: number;
    toYear: number;
    targetPhotos: number;
    maxPages: number;
};

type AutoflowStatusUpdatedProps = {
    datasource: string;
    status: string;
    amountPhotos?: number;
};

type EditDialogOpenedProps = TrackingProduct;
type EditPhotoRemovedProps = TrackingProduct;
type EditPageRemovedProps = TrackingProduct;
type EditSpreadAddedProps = TrackingProduct;
type EditPhotoAddedProps = TrackingProduct;
type EditPhotoSavedProps = TrackingProduct;
type EditPhotoUploadedProps = TrackingProduct & { amount_photos_uploaded: number };
type EditPhotoReplacedProps = TrackingProduct;
type EditPhotoMadeCoverProps = TrackingProduct;
