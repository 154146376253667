import UAParser from 'ua-parser-js';
import isbot from 'isbot';
import { v4 } from 'uuid';
import merge from 'deepmerge';
import { AutoQueue } from './queue';
import {
    EventProps,
    Events,
    TrackingProduct,
} from '../../shared/types/tracking';
import { leaveBreadcrumb } from './errorReporting';
import { Currency, PRODUCT_TYPE } from '../../shared/types/model';
import { getProduct } from './product';
import { createTrackingProduct } from '../../shared/helpers/tracking';

const queue = new AutoQueue();

let pageLocale: string;
let pageCurrency: Currency;

const isBot = () =>
    typeof navigator !== 'undefined' && isbot(navigator.userAgent);

export const getAbTestValues = () => {
    // Get all tests from localStorage. This doesn't work for polyfills but that's alright
    // Cannot use emitter here because that doesn't work on pages where the a/b test is turned off.
    const abTestValues = Object.keys(localStorage as Record<string, string>)
        .filter((key) => key.includes('PUSHTELL-'))
        .reduce((acc, val) => {
            const key = val.replace('PUSHTELL-', 'Test: ');
            acc[key] = localStorage.getItem(val) || '';
            return acc;
        }, {} as Record<string, string>);
    return abTestValues;
};

export const getUTMTags = () => {
    // First touch UTM parameters
    const firstTouchUTMStore = localStorage?.getItem('firstTouchUTM');
    let firstTouchUTM: {
        utm_source?: string;
        utm_medium?: string;
        utm_campaign?: string;
        utm_term?: string;
        utm_content?: string;
        referrer?: string;
    } = {};
    if (firstTouchUTMStore) {
        firstTouchUTM = JSON.parse(JSON.parse(firstTouchUTMStore));
    }

    // Last touch UTM parameters
    const lastTouchUTMStore = sessionStorage?.getItem('lastTouchUTM');
    let lastTouchUTM: {
        'utm_source [last touch]'?: string;
        'utm_medium [last touch]'?: string;
        'utm_campaign [last touch]'?: string;
        'utm_term [last touch]'?: string;
        'utm_content [last touch]'?: string;
        'referrer [last touch]'?: string;
    } = {};
    if (lastTouchUTMStore) {
        const lastTouchUTMParams = JSON.parse(JSON.parse(lastTouchUTMStore));
        // Overwrite key names
        lastTouchUTM = Object.entries(lastTouchUTMParams).reduce(
            (acc, [utmKey, value]) => {
                acc[`${utmKey} [last touch]`] = value;
                return acc;
            },
            {} as Record<string, unknown>
        );
    }

    return { firstTouchUTM, lastTouchUTM };
};

let deviceInfo: { device?: string; os?: string } = {};
export const getDevice = () => {
    if (deviceInfo.device && deviceInfo.os) {
        return deviceInfo;
    }
    const parser = new UAParser();
    const result = parser.getResult();

    deviceInfo = {
        device: result.device.type,
        os: result.os.name,
    };
    return deviceInfo;
};

const appendEvent = async () => {
    const currency = localStorage?.getItem('currency');
    const { firstTouchUTM, lastTouchUTM } = getUTMTags();

    return {
        locale: pageLocale,
        currency: currency || pageCurrency,
        ...firstTouchUTM,
        ...lastTouchUTM,
        ...getAbTestValues(),
    };
};

export const getTrackingUserId = async () => {
    if (!window.rudderanalytics) {
        // No rudderanalytics and no account, user Id is unknowable so just take random
        return v4();
    }

    return window.rudderanalytics.getAnonymousId();
};

export const track = async <T extends keyof EventProps>(
    name: T,
    dataMaybePromise: EventProps[T] | Promise<EventProps[T]>,
    options: any = {}
) => {
    if (!window.rudderanalytics || isBot()) {
        return;
    }

    const data = await dataMaybePromise;

    queue.enqueue(async () => {
        const { device, os } = getDevice();

        const props = { ...data, ...(await appendEvent()) };
        window.rudderanalytics.track(
            name,
            props,
            merge(options, {
                context: {
                    device: { name: device },
                    os: { name: os },
                },
            })
        );

        leaveBreadcrumb(name, props);
    });
};

export const identify = async (
    properties: Record<string, any>,
    options?: any
) => {
    if (!window.rudderanalytics || isBot()) {
        return;
    }

    queue.enqueue(async () => {
        const { device, os } = getDevice();
        const country = sessionStorage.getItem('country');

        // If we have a (new) email, set it in localstorage for GA4
        // enhanced ecommerce tracking because google :shrug:
        // This property name is hardcoded in tag manager
        if (properties.email) {
            localStorage.setItem('ga4_user_property_email', properties.email);
        }

        window.rudderanalytics.identify(
            await getTrackingUserId(),
            {
                ...properties,
                ...(await appendEvent()),
                country,
            },
            merge(options, {
                context: {
                    device: { name: device },
                    os: { name: os },
                },
            })
        );
    });
};

export const reset = async () => {
    if (!window.rudderanalytics || isBot()) {
        return;
    }

    window.rudderanalytics.reset();
};

export const page = async (name: string) => {
    if (!window.rudderanalytics || isBot()) {
        return;
    }

    // Check if we need to fire a Visit Start and do it if necessary
    const visitStartTime = localStorage.getItem('visitStartTime');
    if (
        !visitStartTime ||
        parseInt(visitStartTime) < Date.now() - 30 * 60 * 1000
    ) {
        localStorage.setItem('visitStartTime', Date.now().toString());
        track(Events.StartVisit, {});
    }

    queue.enqueue(async () => {
        const { device, os } = getDevice();

        window.rudderanalytics.page('mrcollage', name, await appendEvent(), {
            context: {
                device: { name: device },
                os: { name: os },
            },
        });
    });
};

export const timeOnWebsiteTrack = () => {
    const siteStartStr = localStorage.getItem('siteStart');
    const lastSeenStr = localStorage.getItem('lastSeen');

    let siteStart = siteStartStr ? parseInt(siteStartStr) : undefined;
    const lastSeen = lastSeenStr ? parseInt(lastSeenStr) : 0;
    if (!siteStart || Date.now() - lastSeen > 30 * 60 * 1000) {
        siteStart = Date.now();
        localStorage.setItem('siteStart', siteStart.toString());
        localStorage.removeItem('lastSeen');
        localStorage.removeItem('sent05SecFBEvent');
        localStorage.removeItem('sent10SecFBEvent');
        localStorage.removeItem('sent15SecFBEvent');
        localStorage.removeItem('sent30SecFBEvent');
        localStorage.removeItem('sent60SecFBEvent');
    }

    localStorage.setItem('lastSeen', Date.now().toString());

    if (
        Date.now() - siteStart > 5000 &&
        !localStorage.getItem('sent05SecFBEvent')
    ) {
        localStorage.setItem('sent05SecFBEvent', 'yes');
        track(Events.Qvisit05, {});
    }

    if (
        Date.now() - siteStart > 10000 &&
        !localStorage.getItem('sent10SecFBEvent')
    ) {
        localStorage.setItem('sent10SecFBEvent', 'yes');
        track(Events.Qvisit10, {});
    }

    if (
        Date.now() - siteStart > 15000 &&
        !localStorage.getItem('sent15SecFBEvent')
    ) {
        localStorage.setItem('sent15SecFBEvent', 'yes');
        track(Events.Qvisit15, {});
    }

    if (
        Date.now() - siteStart > 30000 &&
        !localStorage.getItem('sent30SecFBEvent')
    ) {
        localStorage.setItem('sent30SecFBEvent', 'yes');
        track(Events.Qvisit30, {});
    }

    if (
        Date.now() - siteStart > 60000 &&
        !localStorage.getItem('sent60SecFBEvent')
    ) {
        localStorage.setItem('sent60SecFBEvent', 'yes');
        track(Events.Qvisit60, {});
    }
};

export const createTrackingProductFrontend = async (data: {
    jobIdOrName: string;
    productType: PRODUCT_TYPE;
    locale: string;
    currency: Currency;
    quantity?: number;
}): Promise<TrackingProduct> => {
    const product = await getProduct(data.jobIdOrName);

    if (!product) {
        throw Error('Cannot find product');
    }

    return createTrackingProduct({
        jobIdOrName: data.jobIdOrName,
        product,
        productType: data.productType,
        locale: data.locale,
        currency: data.currency,
        quantity: data.quantity,
    });
};
