import React from 'react';
import i18n from '../i18n';
import pageMap from '../pageMap.json';
import { Currency, ElementType } from '../../shared/types/model';

export type PageContextType = {
    locale: ElementType<(typeof i18n)['locales']>;
    currency: Currency;
    template: keyof typeof pageMap | 'orders' | 'coupons' | '';
};

const defaults: PageContextType = {
    locale: 'en-us' as ElementType<(typeof i18n)['locales']>,
    currency: Currency.USD,
    template: '404',
};

export const PageContext = React.createContext(defaults);

export const PageProvider = (props: { children: any; context: any }) => {
    return (
        <PageContext.Provider value={props.context}>
            {props.children}
        </PageContext.Provider>
    );
};
