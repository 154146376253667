import { Country } from './types/country';
import { Currency } from './types/model';

const { EUR, GBP, USD } = Currency;

export const shippingPricing = {
    // US
    [Country.US]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },

    // EU
    [Country.AT]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.NL]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.BE]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.BG]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.HR]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.CY]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.CZ]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.DK]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.EE]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.FI]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.FR]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.GB]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.GR]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.HU]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.IE]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.IT]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.LV]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.LT]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.LU]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.MT]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.PL]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.PT]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.RO]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.SK]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.SI]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.ES]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.SE]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },

    [Country.NO]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.CA]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.CH]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.IS]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.LI]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.AU]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.NZ]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    [Country.SG]: { [EUR]: 0, [USD]: 0, [GBP]: 0 },
    default: { [EUR]: 999, [USD]: 999, [GBP]: 999 },
};

export const getShippingPrice = (country: Country | 'default', currency: Currency) => {
    // @ts-expect-error default case handles the error
    return (shippingPricing[country] || shippingPricing.default)[currency] as number;
};
