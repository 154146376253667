import messages from '../../i18n-translations.json';
import i18n from '../../src/i18n';
import pageMap from '../../src/pageMap.json';
import { calculateCartItemPrice } from '../pricing';
import { Currency, PRODUCT_TYPE, SKU } from '../types/model';
import { TrackingProduct } from '../types/tracking';
import { TPhotoBook } from '../types/photobook';
import { nameToJobArn } from './job';

const calculateAmountPhotosInProduct = (product: TPhotoBook) => {
    return product.pages.reduce((acc, val) => {
        return acc + val.containers.length;
    }, 0);
};

export const createTrackingProduct = (data: {
    jobIdOrName: string;
    productType: PRODUCT_TYPE;
    product: TPhotoBook;
    locale: string;
    currency: Currency;
    quantity?: number;
}): TrackingProduct => {
    const arn = nameToJobArn(data.jobIdOrName);
    const language = data.locale.split('-')[0];

    const price = calculateCartItemPrice(
        {
            productType: data.productType,
            productInfo: {
                photobook: {
                    jobName: data.jobIdOrName,
                    pages: data.product?.pages.length ?? 0,
                },
            },
            quantity: 1,
        },
        data.currency
    );

    const editPageHref = `${
        data.locale === i18n.defaultLocale ? '' : `${data.locale}/`
        // @ts-expect-error any thingy
    }${pageMap['photobook/edit'][language]}`;

    return {
        cart_id: '1',
        product_id: arn,
        sku: SKU.PHOTOBOOK_HARDCOVER_A4_HOR,
        category: data.productType,
        name: data.productType,
        translatedName:
            messages[language as keyof typeof messages][
                `product_types_${data.productType}`
            ],
        brand: 'MrCollage',
        variant: SKU.PHOTOBOOK_HARDCOVER_A4_HOR,
        currency: data.currency,
        quantity: data.quantity ?? 1,
        price: price / 100,
        url: `${process.env.GATSBY_BASE_URL!.replace(/\/$/, '')}/${editPageHref}/${data.jobIdOrName}`,
        image_url: `https://${process.env.GATSBY_DATASOURCE_BUCKET_NAME}.s3.eu-west-1.amazonaws.com/${arn}/preview.jpg`,

        amount_photos: calculateAmountPhotosInProduct(data.product),
        amount_pages: data.product?.pages.length ?? 0,
        productGenerationError: data.product.totalError,
    };
};
