/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import React, { useEffect } from 'react';
import { experimentDebugger } from '@marvelapp/react-ab-test';
import { useLocalStorage, useReadLocalStorage, useSessionStorage } from 'usehooks-ts';
import { QueryClient, QueryClientProvider } from 'react-query';

import { RootComponent } from './src/components/RootComponent';
import './src/styles/global.css';
// eslint-disable-next-line
import '/node_modules/flag-icons/css/flag-icons.min.css';
import {
    getCart,
    setCartInfo,
    addCoupon,
} from './src/helpers/cart';
import { identify, page, timeOnWebsiteTrack } from './src/helpers/track';
import { debounce } from './src/helpers/debounce';
import { useAsyncEffect } from './src/hooks/useAsyncEffect';
import { getCoupon, validateCoupons } from './src/helpers/coupon';
import * as errorReporting from './src/helpers/errorReporting';

const queryClient = new QueryClient();

const debouncedPageEvent = debounce((pathname: string) => {
    page(pathname);
}, 500);

errorReporting.initialize();
const ErrorBoundary = errorReporting.getErrorBoundary();

export const wrapPageElement = ({ element, props }) => {
    const [firstTouchUTM, setFirstTouchUTM] = useLocalStorage('firstTouchUTM', '');
    const [lastTouchUTM, setLastTouchUTM] = useSessionStorage('lastTouchUTM', '');
    const [, setCycler] = useSessionStorage('cycler', '');
    const [, setEditorbanner] = useSessionStorage('editorbanner', '');
    const [startTime, setStartTime] = useSessionStorage('startTime', 0);
    const [country, setCountry] = useLocalStorage('country', '');
    const [ip, setIp] = useLocalStorage('ip', '');
    const email = useReadLocalStorage('email');

    experimentDebugger.setDebuggerAvailable(
        process.env.GATSBY_ENV !== 'production'
    );
    if (process.env.GATSBY_ENV !== 'production') {
        // Disable error printing here as this is not ready for React 18
        const consoleError = console.error;
        console.error = () => {};
        experimentDebugger.enable();
        console.error = consoleError;
    } else {
        experimentDebugger.disable();
    }

    const setUTMParameters = (params: URLSearchParams) => {
        const utmParams = {
            utm_source: params.get('utm_source'),
            utm_medium: params.get('utm_medium'),
            utm_campaign: params.get('utm_campaign'),
            utm_term: params.get('utm_term'),
            utm_content: params.get('utm_content'),
            referrer: document?.referrer,
        };
        // Check if any are set
        if (Object.values(utmParams).some((param) => !!param)) {
            // First touch should only be set when it is not yet set in the localStorage.
            if (!firstTouchUTM) {
                setFirstTouchUTM(JSON.stringify(utmParams));
            }

            // Last touch should only be set when it is not yet set in the sessionStorage.
            if (!lastTouchUTM) {
                setLastTouchUTM(JSON.stringify(utmParams));
            }
        }
    };

    const setMarketingParameters = (searchParams: URLSearchParams) => {
        if (searchParams.get('cycler')) {
            setCycler(JSON.stringify(searchParams.get('cycler')!.split(',')));
        }

        if (searchParams.get('editorbanner')) {
            setEditorbanner(searchParams.get('editorbanner')!);
        }

        if (!startTime) {
            setStartTime(Date.now());
        }
    };

    const setUserInfo = async () => {
        if (country && ip) {
            return;
        }

        const response = await fetch(
            `${process.env.GATSBY_USER_INFO_API_ENDPOINT}`
        );
        const result = await response.json();
        setCountry(result.result.country);
        setIp(result.result.ip);
    };

    // browser init things (in useEffect so no SSR)
    useEffect(() => {
        const searchParams = new URLSearchParams(props.location.search);
        setUTMParameters(searchParams);
        setUserInfo();
        setCartInfo({
            locale: props.pageContext.locale,
            currency: props.pageContext.currency,
        });

        debouncedPageEvent(props.location.pathname);
        timeOnWebsiteTrack();

        setMarketingParameters(searchParams);
    }, [props.location.pathname]);

    useAsyncEffect(async () => {
        if (email) {
            identify({ email });
        }

        // Check to add coupon
        const searchParams = new URLSearchParams(props.location.search);
        const cart = getCart();

        // Validate all coupons
        await validateCoupons();

        if (searchParams.get('coupon') && (cart?.coupons ?? []).length === 0) {
            const result = await getCoupon(searchParams.get('coupon')!.toUpperCase());
            if (result?.coupon) {
                addCoupon(result);
            }
        }
    }, []);

    return <QueryClientProvider client={queryClient}>
        {/* @ts-expect-error bugsnag error boundary */}
        <ErrorBoundary>
            <RootComponent {...props}>
                {element}
            </RootComponent>
        </ErrorBoundary>
    </QueryClientProvider>;
};
