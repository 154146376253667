/* eslint-disable no-console */
import * as React from 'react';
import Bugsnag, { Event, NotifiableError, OnErrorCallback } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

let isInitialized = false;

const isCorrectEnvironment =
    process.env.GATSBY_ENV === 'staging' ||
    process.env.GATSBY_ENV === 'production';

export const initialize = () => {
    if (isCorrectEnvironment && process.env.GATSBY_BUGSNAG_KEY) {
        Bugsnag.start({
            apiKey: process.env.GATSBY_BUGSNAG_KEY,
            plugins: [new BugsnagPluginReact(React)],
            appVersion: process.env.GATSBY_BUILD_ID ?
                `editor-${process.env.GATSBY_BUILD_ID}` :
                'editor-undefined',
            maxBreadcrumbs: 100,
        });
        isInitialized = true;
    }
};

export const getErrorBoundary = () => {
    if (isInitialized) {
        return Bugsnag.getPlugin('react');
    }
    return React.Fragment;
};

export const reportError = (err: Error) => {
    if (isInitialized) {
        Bugsnag.notify(err);
    }
};

export const setUser = (userData: any) => {
    if (isInitialized) {
        Bugsnag.addMetadata('userData', userData);
        if (userData.attributes) {
            Bugsnag.setUser(userData.attributes.sub, userData.attributes.email);
        }
    } else {
        console.info('user', userData);
    }
};

export const notify = (error: NotifiableError, onError?: OnErrorCallback) => {
    console.log(error);
    if (isInitialized) {
        console.log('notify');
        Bugsnag.notify(error, onError);
    } else {
        console.error(error);
        if (onError) {
            const fakeEvent = {
                addMetadata: console.error,
            } as unknown as Event;
            onError(fakeEvent, () => {});
        }
    }
};

export const leaveBreadcrumb = (name: string, props: any) => {
    if (isInitialized) {
        Bugsnag.leaveBreadcrumb(name, props);
    }
};
