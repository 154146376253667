import { PRODUCT_TYPE } from '../../shared/types/model';
import { TPhotoBook } from '../../shared/types/photobook';
import { nameToJobArn } from '../../shared/helpers/job';

const removePhotobookSources = (photobook: TPhotoBook) => {
    return {
        ...photobook,
        front: {
            ...photobook.front,
            container: {
                ...photobook.front.container,
                photo: {
                    ...photobook.front.container.photo,
                    mobile: {
                        ...photobook.front.container.photo.mobile,
                        source: undefined,
                    },
                    desktop: {
                        ...photobook.front.container.photo.desktop,
                        source: undefined,
                    },
                },
            },
        },
        back: {
            ...photobook.back,
            container: photobook?.back?.container?.photo ?
                {
                    ...photobook.back.container,
                    photo: {
                        ...photobook.back.container.photo,
                        mobile: {
                            ...photobook.back.container.photo.mobile,
                            source: undefined,
                        },
                        desktop: {
                            ...photobook.back.container.photo.desktop,
                            source: undefined,
                        },
                    },
                } :
                {},
        },
        pages: photobook.pages.map((page) => {
            return {
                ...page,
                containers: page.containers.map((container) => {
                    return {
                        ...container,
                        photo: {
                            ...container.photo,
                            mobile: {
                                ...container.photo.mobile,
                                source: undefined,
                            },
                            desktop: {
                                ...container.photo.desktop,
                                source: undefined,
                            },
                        },
                    };
                }),
            };
        }),
    };
};

const removeSources = (productType: PRODUCT_TYPE, dataWithSource: TPhotoBook) => {
    if (productType === PRODUCT_TYPE.photobook) {
        return removePhotobookSources(dataWithSource);
    }

    throw Error('Incorrect product type');
};

const productCache: Record<string, TPhotoBook | undefined> = {};

export const saveProduct = async (
    productType: PRODUCT_TYPE,
    jobNameOrId: string,
    dataWithSource: TPhotoBook
) => {
    const data = removeSources(productType, dataWithSource);

    await fetch(`${process.env.GATSBY_DATASOURCE_API_ENDPOINT}/putProduct`, {
        body: JSON.stringify({
            productType,
            jobId: jobNameOrId,
            definition: data,
        }),
        method: 'POST',
    });

    // Clear the cache
    const jobName = jobNameOrId.includes(':states:') ?
        jobNameOrId :
        nameToJobArn(jobNameOrId);
    productCache[jobName] = undefined;
};

export const getProduct = async (jobNameOrId: string) => {
    const jobName = jobNameOrId.includes(':states:') ?
        jobNameOrId :
        nameToJobArn(jobNameOrId);

    if (productCache[jobName]) {
        return productCache[jobName];
    }

    const response = await fetch(
        `${process.env.GATSBY_DATASOURCE_API_ENDPOINT}/getProduct/${jobName}/photobook`
    );

    const result = await response.json();
    productCache[jobName] = result.result as TPhotoBook;

    return productCache[jobName];
};
